// Configuration
const StoreLocatorConfig = {
    api: {
        key: "AIzaSyAejsbrAZ_Kxf-0gbRHbEOAu-x0eryxv-I",
        version: "weekly",
        mapId: "3668c54d0979f3be",
        endpoints: {
            stores: '/wp-json/og/v1/stores'
        }
    },
    map: {
        defaultCenter: { lat: 35.4820, lng: -79.1803 },
        styles: [
            { stylers: [{ lightness: 20 }] },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            { featureType: "transit", stylers: [{ visibility: "off" }] }
        ],
        zoom: {
            desktop: 6,
            mobile: 5,
            mobileBreakpoint: 768
        }
    },
    geolocation: {
        options: {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        }
    }
};

// Map Module
const MapModule = {
    map: null,
    markers: {},
    async initMap(element, locations, onMarkerClick) {
        if (!element) return;

        const { Map } = await google.maps.importLibrary("maps");
        const width = window.innerWidth;
        const zoom = width < StoreLocatorConfig.map.zoom.mobileBreakpoint
            ? StoreLocatorConfig.map.zoom.mobile
            : StoreLocatorConfig.map.zoom.desktop;

        this.map = new Map(element, {
            center: this.calculateCenter(locations),
            zoom,
            mapId: StoreLocatorConfig.api.mapId,
            styles: StoreLocatorConfig.map.styles
        });

        await this.setMarkers(locations, onMarkerClick);
        return this.map;
    },

    calculateCenter(locations) {
        if (!locations.length) return StoreLocatorConfig.map.defaultCenter;

        const total = locations.reduce((acc, loc) => ({
            lat: acc.lat + parseFloat(loc.lat),
            lng: acc.lng + parseFloat(loc.lng)
        }), { lat: 0, lng: 0 });

        return {
            lat: total.lat / locations.length,
            lng: total.lng / locations.length
        };
    },

    async setMarkers(locations, onMarkerClick) {
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
        const { InfoWindow } = await google.maps.importLibrary("maps");
        const infoWindow = new InfoWindow();

        // Clear existing markers
        Object.values(this.markers).forEach(marker => { marker.map = null; });
        this.markers = {};

        if (!locations.length) return;

        locations.forEach(location => {
            const marker = new AdvancedMarkerElement({
                map: this.map,
                title: location.name,
                content: this.createMarkerIcon(),
                position: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng)
                }
            });

            marker.addListener("click", () => {
                infoWindow.close();
                infoWindow.setContent(this.createInfoWindowContent(location));
                infoWindow.open(marker.map, marker);
                this.map.panTo(marker.position);
                if (onMarkerClick) onMarkerClick(location);
            });

            this.markers[location.id] = marker;
        });
    },

    createMarkerIcon() {
        const parser = new DOMParser();
        return parser.parseFromString(`<svg xmlns="http://www.w3.org/2000/svg" width="37.813" height="51.512" viewBox="0 0 37.813 51.512">
            <path d="M1389.908,1322.977a10.037,10.037,0,0,0-20.073-.232,10.038,10.038,0,1,0,20.073.232m-7.771-18.477c1.122.292,2.26.535,3.365.883,9.458,2.973,15.268,13.472,11.639,23.3a54.732,54.732,0,0,1-7.09,13.079c-3.177,4.4-6.559,8.65-9.907,13.038a7.757,7.757,0,0,1-.706-.587,118.13,118.13,0,0,1-13.529-18.513,32.838,32.838,0,0,1-4.116-10.047c-1.677-8.81,3.995-17.8,12.81-20.382.992-.291,2-.513,3.007-.767Z" transform="translate(-1360.975 -1304)" fill="#004155" stroke="#fff" stroke-width="1"/>
            <g transform="translate(8.025 8)" fill="#589c83" stroke="#fff" stroke-width="1">
              <circle cx="11" cy="11" r="11" stroke="none"/>
              <circle cx="11" cy="11" r="10.5" fill="none"/>
            </g>
        </svg>`, "image/svg+xml").documentElement;
    },

    createInfoWindowContent(location) {
        return `
            <div class="store-locator-info-window">
                <a href="${location.store_link}">${location.name}</a>
                <address>
                    <p>${location.address}</p>
                    ${location.address2 ? `<p>${location.address2}</p>` : ""}
                    <p>${location.city}, ${location.state} ${location.zip}</p>
                </address>
                ${location.phone ? `<p><strong>Phone:</strong> <a href="tel:${location.phone}">${location.phone}</a></p>` : ""}
                ${location.email ? `<p><strong>Email: </strong><a href="mailto:${location.email}">${location.email}</a></p>` : ""}
            </div>`;
    },

    updateView(locations) {
        this.setMarkers(locations);
        this.recenterMap(locations);
    },

    recenterMap(locations) {
        const center = this.calculateCenter(locations);
        this.map.panTo(center);

        const width = window.innerWidth;
        const baseZoom = width < StoreLocatorConfig.map.zoom.mobileBreakpoint
            ? StoreLocatorConfig.map.zoom.mobile
            : StoreLocatorConfig.map.zoom.desktop;

        this.map.setZoom(locations.length > 6 ? baseZoom : baseZoom + 1);
    },

    setMarkerAnimation(storeId, shouldBounce) {
        const marker = this.markers[storeId];
        if (!marker) return;

        if (shouldBounce) {
            marker.content.classList.add('bounce');
            // Center map on bouncing marker with smooth animation
            this.map.panTo(marker.position);
        } else {
            marker.content.classList.remove('bounce');
        }
    }
};

// Location Service
const LocationService = {
    async getStores(filters = null) {
        const url = new URL(StoreLocatorConfig.api.endpoints.stores, window.location.origin);

        if (filters) {
            Object.entries(filters).forEach(([key, value]) => {
                if (value) url.searchParams.set(key, value);
            });
        }


        const response = await fetch(url);
        const data = await response.json();
        return data;
    },

    async getUserLocation() {
        if (!navigator.geolocation) {
            console.error('Geolocation is not supported by this browser.');
            return null;
        }

        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, StoreLocatorConfig.geolocation.options);
            });

            return {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };
        } catch (error) {
            console.error('Error getting user location:', error);
            return null;
        }
    },

    formatUserLocation(location) {
        if (!location) return null;
        return `${encodeURIComponent(location.latitude)},${encodeURIComponent(location.longitude)}`;
    },

    isValidZipcode(zipcode) {
        return zipcode?.toString().length === 5;
    }
};

// URL State Manager
const URLStateManager = {
    syncFilters(filters, initialLoad = false) {
        const url = new URL(window.location);

        Object.entries(filters).forEach(([param, value]) => {
            if (initialLoad) {
                filters[param] = url.searchParams.get(param) || '';
            } else if (value && value !== '') {
                url.searchParams.set(param, value);
            } else {
                url.searchParams.delete(param);
            }
        });

        if (!initialLoad) {
            window.history.replaceState({}, '', url);
        }

        return filters;
    }
};

// Main Store Locator Component
window.StoreLocator = () => {
    return {
        map: null,
        locations: [],
        noLocations: false,
        isLoading: false,
        initialUserLocation: null,
        filters: {
            area: '',
            radius: '200',
            services: ''
        },

        async init() {
            URLStateManager.syncFilters(this.filters, true);

            this.$watch('filters', () => this.handleFilterChange());

            await this.initializeWithLocation();
        },

        async handleSearch(event) {
            event?.preventDefault();
            await this.handleFilterChange();
        },

        resetFilters() {
            this.filters = {
                area: '',
                radius: '200',
                services: ''
            };
            URLStateManager.syncFilters(this.filters);
            this.handleFilterChange();
        },

        async handleFilterChange() {
            URLStateManager.syncFilters(this.filters);

            if (this.filters.area && !LocationService.isValidZipcode(this.filters.area)) {
                this.noLocations = true;
                return;
            }

            this.isLoading = true;
            try {
                const response = await this.fetchStores();
                this.updateLocations(response);
            } catch (error) {
                console.error('Error fetching stores:', error);
                this.noLocations = true;
            } finally {
                this.isLoading = false;
            }
        },

        async initializeWithLocation() {
            this.isLoading = true;
            try {
                this.initialUserLocation = await LocationService.getUserLocation();
                const response = await this.fetchStores();
                this.updateLocations(response);

                if (!this.map) {
                    this.map = await MapModule.initMap(this.$refs.map, this.locations);
                }
            } catch (error) {
                console.error('Error initializing location:', error);
                this.noLocations = true;
            } finally {
                this.isLoading = false;
            }
        },

        async fetchStores() {
            const hasValidZip = LocationService.isValidZipcode(this.filters.area);
            if (hasValidZip) {
                return LocationService.getStores({
                    ...this.filters,
                    listView: 'true'
                });
            }

            const userLocation = LocationService.formatUserLocation(this.initialUserLocation);
            if (userLocation) {
                const userLocationResponse = await LocationService.getStores({
                    ...this.filters,
                    userLocation,
                    listView: 'true'
                });
                if (userLocationResponse.stores.length > 0) {
                    return userLocationResponse;
                }
            }

            return LocationService.getStores({
                ...this.filters,
                userLocation,
                listView: 'true'
            });
        },

        updateLocations(response) {
            const stores = response.stores || response;
            this.locations = stores;
            this.noLocations = stores.length === 0;

            // Update the list view if HTML is provided
            if (response.html) {
                const listContainer = this.$refs.storeLocationsList;
                if (listContainer) {
                    // Use a temporary container to avoid layout shifts
                    const temp = document.createElement('div');
                    temp.innerHTML = response.html;

                    // Replace the content
                    listContainer.innerHTML = temp.firstElementChild.innerHTML;
                }
            }

            if (this.map) {
                MapModule.updateView(stores);
            }
        },

        handleStoreHover(storeId, isEntering) {
            if (this.map) {
                MapModule.setMarkerAnimation(storeId, isEntering);
            }
        }
    };
};

// Initialize Google Maps
(g => {
    var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window;
    b = b[c] || (b[c] = {});
    var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]);
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => h = n(Error(p + " could not load."));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a)
    }));
    d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n))
})({
    key: StoreLocatorConfig.api.key,
    v: StoreLocatorConfig.api.version
});

// Add CSS for bounce animation
const style = document.createElement('style');
style.textContent = `
@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}
.bounce {
    animation: bounce 0.5s 3;
    transform-origin: center bottom;
}`;
document.head.appendChild(style);
